<script>
import {defineComponent} from 'vue'
import Table from "@/components/tables/table.vue";

export default defineComponent({
  name: "Overview",
  data() {
    return {
      tableHeaders: [
        {
          field: 'order',
          label: 'Order',
          align: 'center'
        },
        {
          field: 'lot_number',
          label: 'Lot Number',
          align: 'center'
        },
        {
          field: 'category',
          label: 'Category',
          align: 'center'
        },
        {
          field: 'total_sum',
          label: 'Total Expense',
          align: 'center'
        }
      ]
    }
  },
  components: {
    Table
  },
  computed: {
    tableUrl() {
      return `/counterparty/expanses/${this.$route.params.slug}/`
    }
  }
})
</script>

<template>
  <div class="mb-4">
    <Table name="Counterparty Orders" :searchable="true"
           :headers="tableHeaders" :url="tableUrl"/>
  </div>
</template>

<style scoped>

</style>